import {MSG_ACTION} from "../api/enums.js";
import {DataContent} from "../api/common.js"
import { PcCookie, Key } from '@/utils/cookie.js'

var websock = null;
let rec; //断线重连后，延迟5秒重新创建WebSocket连接  rec用来存储延迟请求的代码
let isConnect = false; //连接标识 避免重复连接
let messageCallBack = null;
let closeCallBack = null;
let openCallBack = null;

let connect = (wsurl,token) => {
	try {
		if (isConnect) {
			return;
		}
		console.log("连接WebSocket: ", wsurl);
		websock = new WebSocket(wsurl+'/'+token); 
		websock.onmessage = function(e) {

            console.log("接收到的消息："+e.data);
			let message = JSON.parse(e.data);
            messageCallBack && messageCallBack(message);
		}
		websock.onclose = function(e) {
			console.log('WebSocket连接关闭')
			isConnect = false; //断开后修改标识
			closeCallBack && closeCallBack(e);
		}
		websock.onopen = function() {
			console.log("WebSocket连接成功");
			isConnect = true;
            openCallBack && openCallBack();
		}

		// 连接发生错误的回调方法
		websock.onerror = function() {
			console.log('WebSocket连接发生错误')
			isConnect = false; //连接断开修改标识
			reconnect(wsurl,token);
		}
	} catch (e) {
		console.log("尝试创建连接失败");
		reconnect(wsurl,token); //如果无法连接上webSocket 那么重新连接！可能会因为服务器重新部署，或者短暂断网等导致无法创建连接
	}
};

//定义重连函数
let reconnect = (wsurl,token) => {
	console.log("尝试重新连接");
	if (isConnect){
		//如果已经连上就不在重连了
		return; 
	}
	rec && clearTimeout(rec);
	rec = setTimeout(function() { // 延迟5秒重连  避免过多次过频繁请求重连
		connect(wsurl,token);
	}, 15000);
};

// let keepalive = () => {
// 	// alive && clearTimeout(alive);
// 	setInterval(function() {
// 		let dataContent = new DataContent(MSG_ACTION.KEEPALIVE, null, null);
// 		websock.send('"'+JSON.stringify(dataContent)+'"')	
// 	}, 10000)
// };

let heartCheck = {
	interval: 60000, //每段时间发送一次心跳包 这里设置为60s
	intervalObj: null, //延时发送消息对象（启动心跳新建这个对象，收到消息后重置对象）
	start: function() {
		clearInterval()
		clearInterval(this.intervalObj);
		this.intervalObj = setInterval(function() {
			let dataContent = new DataContent(MSG_ACTION.KEEPALIVE, null, null);
			websock.send(JSON.stringify(dataContent))	
		}, this.interval)	
	}

}

// 实际调用的方法
let sendMessage = (agentData) => {
	// console.log(globalCallback)
	if (websock.readyState === websock.OPEN) {
		// 若是ws开启状态
		websock.send(JSON.stringify(agentData))
	} else if (websock.readyState === websock.CONNECTING) {
		// 若是 正在开启状态，则等待1s后重新调用
		setTimeout(function() {
			sendMessage(agentData)
		}, 5000)
	} else {
		const token = PcCookie.get(Key.tokenKey)
		connect(process.env.VUE_APP_WS_URL,token);
		// 若未开启 ，则等待1s后重新调用
		setTimeout(function() {
			sendMessage(agentData)
		}, 5000)
	}
}

let onMessage = (callback) => {
	messageCallBack = callback;
}

let onClose = (callback) => {
	closeCallBack = callback;
}

let onOpen = (callback) => {
    openCallBack = callback;    
}

// 将方法暴露出去
export {
	connect,
	reconnect,
	sendMessage,
	onMessage,
	onClose,
    onOpen,
	heartCheck
}

